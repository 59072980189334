import { countries } from 'countries-list';
import { transformCountryCode } from 'utils';
import { BTC, BUSD, CUSD, DASH, DOTS, ETH, INR, NGN, USDT } from 'assets';

type Field = {
  label: string;
  id?: string;
  type?: string;
  name: string;
  linkHref?: string;
  linkText?: string;
  className?: string;
  placeholder?: string;
  fieldTitle?: string;
  value?: string;
  readonly?: boolean;
  options?: {
    label: string;
    value: string;
    Icon?: string | any;
    name?: string;
  }[];
};

export type CustomeSelectChangeType = {
  value: string;
  label: string;
  Icon?: string;
  name?: string;
};

export type PaymentType = {
  name: string;
  label: string;
  placeholder: string;
  type: string;
  value: string;
  isChecked: boolean;
  banks: { value: string; label: string; isChecked: boolean }[];
};

export type Steps =
  | 'about'
  | 'information'
  | 'customise'
  | 'pair'
  | 'payments'
  | 'phoneverification';

export const phoneCodes = transformCountryCode(
  Array.from(
    new Set(Object.values(countries).map((country) => `+${country.phone}`))
  )
);

export const countryCode = Object.values(countries).reduce(
  (country: { [key: string]: string }, item) => {
    const firstCountryCode: string = `+${item.phone.split(',')[0]}`;
    country[item.name] = firstCountryCode;
    return country;
  },
  {}
);

export const paymentMethodField: PaymentType[] = [
  {
    name: 'bank-transfer',
    label: 'Bank Transfer',
    placeholder: 'Please choose one',
    type: 'checkbox',
    value: 'bank-transfer',
    isChecked: false,
    banks: [
      {
        value: 'Zenith Bank',
        label: 'Zenith Bank',
        isChecked: true,
      },
      {
        value: 'Jaiz Bank',
        label: 'Jaiz Bank',
        isChecked: true,
      },
      {
        value: 'United Bank of Africa',
        label: 'United Bank of Africa',
        isChecked: true,
      },
      {
        value: 'Fidelity Bank',
        label: 'Fidelity Bank',
        isChecked: true,
      },
      {
        value: 'Sterling Bank',
        label: 'Sterling Bank',
        isChecked: true,
      },
      {
        value: 'Guarantee Trust Bank',
        label: 'Guarantee Trust Bank',
        isChecked: true,
      },
    ],
  },
  {
    name: 'paypal',
    label: 'Paypal',
    placeholder: 'Please choose one',
    type: 'checkbox',
    value: 'paypal',
    isChecked: false,
    banks: [],
  },
];

export const fields: { [key in Steps]: { [key: string]: Field } } = {
  about: {
    already_running_an_exchange: {
      name: 'already_running_an_exchange',
      label: 'Are you already running an exchange?',
      type: 'radio',
      placeholder: '',
    },
    monthly_volume: {
      name: 'monthly_volume',
      label: 'What’s your monthly volume in USD?',
      type: 'select',
      placeholder: 'Please choose one',
      options: [
        { label: 'Below $10,000', value: 'Below $10,000' },
        { label: '$10,000 - $50,000', value: '$10,000 - $50,000' },
        { label: '$50,000 - $500,000', value: '$50,000 - $500,000' },
        { label: '$500,000 and above', value: '$500,000 and above' },
      ],
    },
  },
  information: {
    firstName: {
      label: 'First Name',
      id: 'firstName',
      name: 'firstName',
      placeholder: 'Your first name',
    },
    LastName: {
      label: 'Last Name',
      id: 'LastName',
      name: 'LastName',
      placeholder: 'Your last name',
    },
    country: {
      label: 'Country/Region',
      type: 'select',
      id: 'country',
      name: 'country',
      placeholder: 'Please select your country',
      options: [],
    },
    city: {
      label: 'City',
      type: 'select',
      id: 'city',
      name: 'city',
      placeholder: 'Select your city',
      options: [],
    },
    phone: {
      label: 'Phone number(*)',
      id: 'phone',
      name: 'phone',
      placeholder: '',
      options: phoneCodes,
    },
    code: {
      label: 'Code',
      id: 'code',
      name: 'code',
      placeholder: 'Code',
      options: phoneCodes,
    },
  },
  customise: {
    business_name: {
      label: 'Business name',
      id: 'business_name',
      name: 'business_name',
      placeholder: 'Enter your business name',
    },
    logo: {
      label: 'Logo',
      type: 'file',
      name: 'logo',
      id: 'logo',
    },
    description: {
      name: 'description',
      label: 'Description',
    },
    brand_color: {
      name: 'brand_color',
      type: 'color',
      label: 'Primary colour',
      placeholder: '#00000',
    },
  },
  pair: {
    pair: {
      name: 'pair_type',
      label: 'Pair type',
      type: 'radio',
      placeholder: 'Buy (allows you to buy crypto from customers)',
      value: 'buy',
    },
    pair1: {
      name: 'pair_type',
      label: '',
      type: 'radio',
      placeholder: 'Sell',
      value: 'sell',
    },
    cryptocurrency: {
      name: 'cryptocurrency',
      label: 'Crypto asset',
      placeholder: 'Crypto asset',
      type: 'select',
      options: [
        { label: 'BTC', value: 'BTC', Icon: BTC, name: 'cryptocurrency' },
        { label: 'USDT', value: 'USDT', Icon: USDT, name: 'cryptocurrency' },
        { label: 'CUSD', value: 'CUSD', Icon: CUSD, name: 'cryptocurrency' },
        { label: 'DASH', value: 'DASH', Icon: DASH, name: 'cryptocurrency' },
        { label: 'BUSD', value: 'BUSD', Icon: BUSD, name: 'cryptocurrency' },
        { label: 'ETH', value: 'ETH', Icon: ETH, name: 'cryptocurrency' },
      ],
    },
    fiat_currency: {
      name: 'fiat_currency',
      label: 'Fiat currency',
      placeholder: 'Fiat currency',
      type: 'select',
      options: [
        { label: 'NGN', value: 'NGN', name: 'fiat_currency', Icon: NGN },
        { label: 'IDR', value: 'IDR', name: 'fiat_currency', Icon: INR },
        { label: 'VND', value: 'VND', name: 'fiat_currency', Icon: DOTS },
        { label: 'PHP', value: 'PHP', name: 'fiat_currency', Icon: DOTS },
      ],
    },
    minimum_amount: {
      name: 'minimum_amount',
      type: 'number',
      label: 'Minimum amount ',
      placeholder: 'Set minimum amount',
    },
    maximum_amount: {
      name: 'maximum_amount',
      type: 'number',
      label: 'Maximum amount ',
      placeholder: 'Set maximum amount',
    },
    price: {
      name: 'price',
      type: 'number',
      label: 'Fixed price/rate',
      placeholder: '0.00',
    },
  },
  payments: {
    'bank-transfer': {
      name: 'bank-transfer',
      label: 'Bank Transfer',
      placeholder: 'Please choose one',
      type: 'checkbox',
      value: 'bank-transfer',
      options: [
        { value: 'Zenith Bank', label: 'Zenith Bank' },
        { label: 'Jaiz Bank', value: 'Jaiz Bank' },
        {
          value: 'United Bank of Africa',
          label: 'United Bank of Africa',
        },
        { value: 'Fidelity Bank', label: 'Fidelity Bank' },
        { value: 'Sterling Bank', label: 'Sterling Bank' },
        { value: 'Guarantee Trust Bank', label: 'Guarantee Trust Bank' },
      ],
    },
    paypal: {
      name: 'paypal',
      label: 'Paypal',
      placeholder: 'Please choose one',
      type: 'checkbox',
      value: 'paypal',
      options: [
        { label: 'Bank Transfer', value: 'bank-transfer' },
        { label: 'Paypal', value: 'paypal' },
      ],
    },
    is_addPayment: {
      name: 'is_addPayment',
      label: 'is_addPayment',
    },
  },
  phoneverification: {
    verify_code: {
      name: 'verify_code',
      id: 'verify_code',
      label: '',
      placeholder: '',
    },
  },
};
