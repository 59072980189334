import React, { useState } from 'react';
import {
  Button, Heading, Paragraph, Text
} from '@payourse/style-guide';
import { useNavigate, useLocation } from 'react-router-dom';
import fusionApi from '../../api/fusionApi';
import { IError } from 'model/signup';
import Alert from './components/Alert';
import { ReactComponent as Back } from 'assets/back.svg';
import { ReactComponent as Envelope } from 'assets/envelope.svg';
import { ReactComponent as PoweredBy } from 'assets/Poweredbypayourselogo.svg';
import { ReactComponent as Simpa } from 'assets/simpa.svg';



export function SignupDone(){

  const [isEmailReSent, setIsEmailReSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const {state} = useLocation();

  if(!state) {
    window.location.href = '/signup'    
  };

    const handleOpenMail = () => {
      window.open(`mailto:${state.email}`)
    }  

    const handleError = (error: IError) => {
      const { exception, statusCode } = error;
      if (statusCode === 400) {
        exception?.fieldErrors['user.email']?.map((item) =>
          setErrorMessage(item.message)
        );
        setIsError(true);
      } else {
        setErrorMessage('Something went wrong, please try again');
        setIsError(true);
      }
    };

    const handleResendVerification = async () => {
      try {
        await fusionApi.resendEmailVerification(state.email);
        setIsEmailReSent(true)
      } catch (error) {
        handleError(error as IError)
      }
    };

  return (
    <main className='SignUpDone Layout'>
    <section className='SignUpDone__Simpa'>
        <Simpa />
      </section>
            <section className='Auth'>
              <section className='Auth__container'>
                <div className='Auth__back'>
                  <Back />
                  <Paragraph size='m' className="Auth__backText" onClick={() => navigate(-1)}>
                    Back 
                  </Paragraph>
                </div>

                <div className='Auth__inboxContent'>
                  <div className='Auth__envelope'>
                    <Envelope />
                  </div>
                  <Heading text='Check your mail' size='xl' element='h1' className='Auth__inboxHeading'/>
                  <Paragraph  size='m' className='Auth__centerText'>
                    We just sent a verification email to
                  </Paragraph>
                  <Text
                    size="l"
                    text={state.email}
                    weight="bold"
                    className='Auth__emailDone'
                  />
                  <Paragraph  size='m'className='Auth__centerText' >
                    Follow the link in your email to finish up creating your account
                  </Paragraph>

                  <Button
                    className="Auth__btn inbox"
                    variant="secondary"
                    type="submit"
                    disabled={false}
                    onClick={handleOpenMail}
                  >
                    Go to inbox
                  </Button>

                  <Paragraph  size='m' className='Auth__centerText'>
                    Didn’t get it? check your spam folder or 
                    <span className='Auth__term-highlight' onClick={handleResendVerification}> Resend</span> 
                  </Paragraph>
                  {
                    isEmailReSent && <Alert message='We just resent the email, please make sure you open the most recent email sent to you.' isError={false}/>
                  }
                  {
                    isError && <Alert message={errorMessage} isError={false} />
                  }
                </div>
              </section>
            </section>
            <section className='SignUpDone__PoweredBy'>
            <PoweredBy />
        </section>
    </main>
    )
}
