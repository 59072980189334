import { useCallback, useEffect, useRef, useState } from 'react';

const noop = () => {};

export function useClickOut<T extends HTMLElement = HTMLDivElement>(
  initialState = false,
  cb = noop
) {
  const [isVisible, setIsVisible] = useState(initialState);
  const clickOutRef = useRef<T>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        clickOutRef.current &&
        !clickOutRef.current?.contains(event.target as Node)
      ) {
        setIsVisible(false);
        cb();
      }
    },
    [cb]
  );

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsVisible(false);
        cb();
      }
    },
    [cb]
  );

  useEffect(() => {
    setIsVisible(initialState);
  }, [initialState]);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('keyup', handleEscape, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keyup', handleEscape, true);
    };
  }, [isVisible, handleClickOutside, handleEscape]);

  return { clickOutRef, isVisible, setIsVisible };
}
