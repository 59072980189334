import { createContext, useContext } from 'react';
import useExchange from './useExchange';

type ContextState = {
  exchange: Exchange;
  isSetupComplete: boolean;
};

export const ExchangeContext = createContext<ContextState | null>(null);
ExchangeContext.displayName = 'ExchangeContext';

function ExchangeContextProvider({ children }: { children: React.ReactNode }) {
  const value = useExchange();
  return (
    <ExchangeContext.Provider value={value}>
      {children}
    </ExchangeContext.Provider>
  );
}

export function useExchangeContext() {
  const context = useContext(ExchangeContext);

  if (!context) {
    throw new Error(
      'useExchangeContext must be used within the ExchangeContextProvider'
    );
  }

  return context;
}

export default ExchangeContextProvider;
