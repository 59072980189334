import './Tooltip.scss';

export function Tooltip({
  children,
  content,
  position = 'top',
}: {
  children: React.ReactNode;
  content: string;
  position?: 'top' | 'bottom';
}) {
  return (
    <div className="CustomTooltip">
      {children}
      <span
        className={`CustomTooltip__content CustomTooltip__content--${position}`}
      >
        {content}
      </span>
    </div>
  );
}
