import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Heading,
  Paragraph,
  Password,
  TextField,
} from '@payourse/style-guide';
import { GoogleButton, Layout } from './components';
import Alert from './components/Alert';

import { IError, ISignup } from 'model/signup';
import fusionApi from 'api/fusionApi';
import { signinSchema } from './schema';
import { useGoogleAuth, useAuth } from './hooks';
import './styles/Auth.scss';
import './styles/Signin.scss';

const initialValues: ISignup = {
  email: '',
  password: '',
};

export function SignIn() {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { signIn } = useGoogleAuth();
  const { completeAuth } = useAuth();

  const handleError = (error: IError) => {
    const { exception, statusCode } = error;
    if (statusCode === 400) {
      exception?.fieldErrors['loginId']?.map((item) =>
        setErrorMessage(item.message)
      );
      setIsError(true);
    } else if (statusCode === 404) {
      setErrorMessage(`Invalid login details for ${values.email}`);
      setIsError(true);
    } else {
      setErrorMessage(`Something went wrong, please try again.`);
      setIsError(true);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await fusionApi.login({
        loginId: values.email,
        password: values.password,
        applicationId: `${process.env.REACT_APP_APPLICATION_ID}`,
      });

      const { token, refreshToken } = response.response;
      completeAuth(token, refreshToken);
      navigate('/');
    } catch (error) {
      handleError(error as IError);
    }
  };
  const { values, errors, getFieldProps, touched } = useFormik({
    initialValues,
    validationSchema: signinSchema,
    onSubmit: handleSubmit,
  });

  const isDisabled: boolean = !!(errors.email || errors.password);

  return (
    <Layout>
      <div className="Auth__container">
        <Heading
          text="Login to Simpa"
          size="xl"
          element="h1"
          className="Auth__title"
        />
        <GoogleButton signIn={signIn} text="Login with Google" />
        <Paragraph size="m" className="Auth__email">
          Or use your email
        </Paragraph>
        {isError && <Alert message={errorMessage} isError={isError} />}
        <div>
          <form onSubmit={handleSubmit}>
            <TextField
              className="Auth__field"
              label="Your email"
              id="email"
              data-testid="email"
              placeholder=""
              error={touched.email ? errors.email : ''}
              {...getFieldProps('email')}
            />
            <Password
              className="Auth__field"
              label="Password"
              type="password"
              id="password"
              data-testid="password"
              placeholder="Enter password"
              autoComplete="current-password"
              error={
                !values.password && touched.password ? errors.password : ''
              }
              {...getFieldProps('password')}
            />
            <Button
              className="Auth__btn login"
              variant="secondary"
              type="submit"
              disabled={isDisabled}
            >
              Log in
            </Button>

            <Paragraph size="m" className="Auth__footNoteSignin">
              Don’t have a Simpa account?
              <NavLink to="/signup" className="Auth__term-highlight">
                {' '}
                Create account
              </NavLink>
            </Paragraph>
            <Paragraph size="m" className="Auth__footNoteSignin">
              Forgot your password?
              <NavLink to="/forgot-password" className="Auth__term-highlight">
                {' '}
                Reset your password
              </NavLink>
            </Paragraph>
          </form>
        </div>
      </div>
    </Layout>
  );
}
