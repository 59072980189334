import { Text } from '@payourse/style-guide';
import { InputHTMLAttributes } from 'react';

export interface BuinessInformation
  extends InputHTMLAttributes<HTMLInputElement> {
  error: string;
  idYes: string;
  idNo: string;
  name: string;
}

export const RadioYesNoButton = ({
  error,
  idYes,
  idNo,
  name,
  ...props
}: BuinessInformation) => {
  
  return (
    <>
      <section className="Radio">
        <div className="Radio__left">
          <label htmlFor={idYes} className="Radio__label">
            <input
              type="radio"
              id={idYes}
              className="Radio__input"
              value="1"
              name={name}
              checked={props.value === 1}
              onChange={props.onChange}
            />
            <span className="Radio__customInput"></span>
            <Text size="m" weight="normal" text="Yes" className="Radio__text" />
          </label>
        </div>
        <div className="Radio__right">
          <label htmlFor={idNo} className="Radio__label">
            <input
              type="radio"
              id={idNo}
              className="Radio__input"
              value="0"
              name={name}
              checked={props.value === 0}
              onChange={props.onChange}
            />
            <span className="Radio__customInput"></span>
            <Text size="m" weight="normal" text="No" className="Radio__text" />
          </label>
        </div>
      </section>
      {error ? (
        <p className="Radio__error-feedback">This is a required field</p>
      ) : null}
    </>
  );
};
