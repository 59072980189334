import './toggle.scss';

export const Toggle = ({
  name,
  text = 'state',
  toggleState,
  handleToggle,
}: {
  name: string;
  text?: string;
  toggleState: string | undefined;
  handleToggle: (event: any) => void;
}) => {
  return (
    <div className="Toggle__switchContainer">
      <label htmlFor={name} className="Toggle__switch">
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={Boolean(toggleState)}
          onChange={handleToggle}
          value={toggleState}
        />
        <span className="Toggle__slider Toggle__slider-round"></span>
      </label>
      <span className="Toggle__statusText">{text}</span>
    </div>
  );
};
