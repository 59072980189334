var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Indicators.scss';
function StatusIndicator(_a) {
    var status = _a.status;
    return (_jsxs("div", __assign({ className: "StatusIndicator" }, { children: [_jsx("span", { className: "StatusIndicator__dot StatusIndicator__dot--" + status }, void 0), _jsx("span", __assign({ className: "StatusIndicator__text" }, { children: status }), void 0)] }), void 0));
}
export default StatusIndicator;
