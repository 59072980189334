var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './styles.scss';
function Heading(_a) {
    var size = _a.size, text = _a.text, Element = _a.element, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsx(Element, __assign({ className: "Heading Heading--" + size + " " + className }, { children: text }), void 0));
}
export default Heading;
