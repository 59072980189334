var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Alert.scss';
import { Icon } from '@blueprintjs/core';
import { colors } from '../../utils/colors';
var iconMap = {
    error: {
        icon: 'error',
        color: colors.baseRed,
    },
    warning: {
        icon: 'warning-sign',
        color: colors.baseOrange,
    },
};
function Alert(_a) {
    var title = _a.title, text = _a.text, level = _a.level, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsxs("aside", __assign({ className: "Alert " + className }, { children: [_jsx(Icon, __assign({}, iconMap[level], { className: "Alert__icon" }), void 0), _jsxs("div", __assign({ className: "Alert__content" }, { children: [_jsx("h3", __assign({ className: "Alert__title" }, { children: title }), void 0), _jsx("p", __assign({ className: "Alert__detail" }, { children: text }), void 0)] }), void 0)] }), void 0));
}
export default Alert;
