import { useEffect, useState } from 'react';

export function useClipBoard() {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => setIsCopied(true));
  };

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [isCopied]);

  return { isCopied, copyToClipboard };
}
