import { useState, useEffect } from 'react';
import { useCustomFetch } from 'hooks';
import { getStorageValue, saveToStorage } from 'utils';

function useExchange() {
  const { fetch } = useCustomFetch<Exchange>();
  const [exchange, setExchange] = useState<Exchange>({} as Exchange);

  useEffect(() => {
    const exchangeID = getStorageValue('exchangeID');
    if (exchangeID) {
      setExchange((previousState) => ({
        ...previousState,
        exchange_id: exchangeID,
      }));
    }
  }, []);

  useEffect(() => {
    fetch(
      {
        url: 'exchange',
      },
      (exchange: Exchange) => {
        saveToStorage('exchangeID', exchange.exchange_id);
        setExchange(exchange);
      }
    );
    // eslint-disable-next-line
  }, [fetch]);

  return {
    exchange,
    isSetupComplete: !!exchange.setup_complete,
  };
}

export default useExchange;
