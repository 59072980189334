import { useState } from 'react';
import { Button, Heading, Paragraph, Password } from '@payourse/style-guide';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import fusionApi from '../../api/fusionApi';
import { forgotPasswordSchema } from './schema';
import Layout from 'components/Auth/components/Layout';
import './styles/Auth.scss';
import Alert from './components/Alert';
import { PasswordStrengthCheck } from './components';

const initialValues = {
  password: '',
  confirm_password: '',
};

export function ResetPassword() {
  const navigate = useNavigate();
  const [isResetTokenUsed, setIsResetTokenUsed] = useState(false);
  const { id } = useParams();

  if (!id) {
    window.location.href = '/signin';
  }
  const handleSubmitRequest = async () => {
    try {
      const payload = {
        password: values.password,
        applicationId: `${process.env.REACT_APP_CLIENT_ID_KEY}`,
      };
      if (id) {
        await fusionApi.changePassword(id, payload);
        navigate('/email-sent', { state: { id: id } });
      }
    } catch (error) {
      setIsResetTokenUsed(true);
    }
  };
  const { values, errors, getFieldProps, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmitRequest,
  });

  const isDisabled = !!(errors.password || errors.confirm_password);

  return (
    <Layout>
      <div className="Auth__container">
        <Heading
          text="Reset password"
          size="xl"
          element="h1"
          className="Auth__title"
        />
        <Paragraph size="m" className="Auth__resetNote">
          We’ll email you a link to reset your password
        </Paragraph>
        <div>
          {isResetTokenUsed && (
            <Alert
              message={
                'Reset token used already! Please try reset your password again.'
              }
              isError={true}
            />
          )}
          <form onSubmit={handleSubmit}>
            <Password
              className="Auth__field"
              label="Set password"
              type="password"
              id="password"
              placeholder="Enter password"
              data-testid="newpassword"
              autoComplete="current-password"
              error={touched.password ? errors.password : ''}
              {...getFieldProps('password')}
            />
            {values.password && errors.password ? (
              <PasswordStrengthCheck password={values.password} />
            ) : null}

            <Password
              className="Auth__field"
              label="Confirm password"
              type="password"
              id="confirm_password"
              data-testid="confirm_password"
              placeholder="Enter same as password"
              autoComplete="current-password"
              error={
                !values.confirm_password && touched.confirm_password
                  ? errors.confirm_password
                  : ''
              }
              {...getFieldProps('confirm_password')}
            />
            <Button
              className="Auth__btn login"
              variant="secondary"
              data-testid="reset_password"
              type="submit"
              disabled={isDisabled}
            >
              Reset password
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
