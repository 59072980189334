var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { TextField } from './TextField';
import { colors } from '../../../utils/colors';
export function PasswordField(_a) {
    var type = _a.type, props = __rest(_a, ["type"]);
    var _b = useState(type), inputType = _b[0], toggleType = _b[1];
    var toggleInputType = function () {
        return toggleType(inputType === 'text' ? 'password' : 'text');
    };
    return (_jsx(TextField, __assign({ type: inputType }, props, { children: _jsx(Icon, { color: colors.dark2, onClick: toggleInputType, icon: inputType === 'password' ? 'eye-off' : 'eye-open' }, void 0) }), void 0));
}
