import { useCallback, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCustomAxios } from './useCustomAxios';
import { ApiError } from 'model/apiError';

type RequestConfig = AxiosRequestConfig & { loadingId?: string };

export function useFetch() {
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const customAxios = useCustomAxios();

  const fn = useCallback(
    async (
      { url, data, loadingId, method = 'GET', ...config }: RequestConfig,
      callback?: (data: any) => void,
      errorCallback?: (error: ApiError) => void
    ) => {
      try {
        if (loadingId) {
          setLoadingStates((s) => ({ ...s, [loadingId]: true }));
        }

        let options = {
          url,
          method,
          ...(data ? { data: JSON.stringify(data) } : {}),
          ...config,
        };

        const response = await customAxios(options);
        callback && callback(response.data.data);
      } catch (error) {
        //TODO: How are we handling errors?
        console.log(error);
        errorCallback && errorCallback(error as ApiError)
        
      } finally {
        if (loadingId) {
          setLoadingStates((s) => ({ ...s, [loadingId]: false }));
        }
      }
    },
    [customAxios]
  );

  return [loadingStates, fn] as const;
}
