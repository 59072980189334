var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Indicators.scss';
import classnames from 'classnames';
function PasswordStrengthIndicator(_a) {
    var _b;
    var strength = _a.strength, _c = _a.className, className = _c === void 0 ? '' : _c;
    var classNames = classnames((_b = {
            PasswordStrengthIndicator: true,
            'PasswordStrengthIndicator--default': strength <= 25,
            'PasswordStrengthIndicator--weak': strength > 25 && strength <= 50,
            'PasswordStrengthIndicator--okay': strength > 50 && strength <= 75,
            'PasswordStrengthIndicator--strong': strength > 75 && strength <= 100
        },
        _b[className] = !!className,
        _b));
    return (_jsxs("div", __assign({ className: classNames }, { children: [_jsx("div", { className: "Indicator--1" }, void 0), _jsx("div", { className: "Indicator--2" }, void 0), _jsx("div", { className: "Indicator--3" }, void 0), _jsx("div", { className: "Indicator--4" }, void 0)] }), void 0));
}
export default PasswordStrengthIndicator;
