export const colors = {
  white: '#ffffff',
  light_red_1: '#fef6f6',
  light_red_2: '#fae2e2',
  base_red: '#ec4c47',
  dark_red: '#bf0e08',
  error_red: '#d14343',
  text_error_red: '#a73636',
  dark_text_red: '#7d2828',
  error_bg_red: '#fdf4f4',
  error_bg: '#f9dada',
  light_orange_1: '#fdf8f3',
  light_orange_2: '#fae3cd',
  base_orange: '#d9822b',
  dark_orange: '#95591e',
  light_yellow_1: '#fef8e7',
  light_yellow_2: '#fbe6a2',
  base_yellow: '#f7d154',
  dark_yellow: '#7e6514',
  dark_yellow_2: '#ffb020',
  light_green_1: '#f1faf5',
  light_green_2: '#d4eee2',
  light_green_3: '#dcf2ea',
  base_green: '#47b881',
  accent_green: '#eef8f4',
  dark_green: '#00783e',
  hover_green: '#339a73',
  success_green: '#52bd94',
  success_light_green: '#f5fbf8',
  success_default_green: '#317159',
  text_green_1: '#429777',
  text_green_2: '#47b881',
  focus_green: '#a3e6cd',
  light_teal_1: '#f1fbfc',
  light_teal_2: '#d2eef3',
  light_teal_3: '#fafbff',
  base_teal: '#14b5d0',
  dark_teal: '#007489',
  light_blue_1: '#f6f9fd',
  light_blue_2: '#dbeaf7',
  base_blue: '#1070ca',
  dark_blue: '#084b8a',
  light_purple_1: '#f8f7fc',
  light_purple_2: '#eae7f8',
  base_purple: '#7540f6',
  dark_purple: '#3608aa',
  neutral_1: '#f5f8f9',
  neutral_2: '#edf0f2',
  neutral_3: '#e4e7eb',
  neutral_4: '#edeff5',
  neutral_5: '#f4f6fa',
  neutral_6: '#8f95b2',
  neutral_7: '#e6e8f0',
  neutral_8: '#f9fafc',
  dark_1: '#66788a',
  dark_2: '#425a70',
  dark_3: '#234361',
  dark_4: '#435a6f',
  dark_5: '#696f8c',
  light_1: '#d7dae5',
  grey_1: '#d8dae5',
  grey_2: '#474d66',
  grey_3: '#e6e8e0',
  grey_4: '#a8adb7',
  grey_5: '#e4e7ec',
  grey_6: '#eeeff5',
  grey_7: '#00000080',
  grey_8: '#e6e8f0',
  focus_grey: '#d6e0ff',
  font_base: '#101840',
};
