import React from 'react';
import { Heading } from '@payourse/style-guide';
import './Spinner.scss';

export function Spinner() {
  return (
    <div className="Spinner">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20"></circle>
      </svg>

      <Heading size="l" text="Loading" element="h1" />
    </div>
  );
}
