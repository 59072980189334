import React from 'react';
import { Paragraph } from '@payourse/style-guide';
import { Loader, PoweredBy, SimpaText } from 'assets';
import './styles.scss';

export const VerifiedUserCheck = () => {
  return (
    <section className="VerifiedUserCheck">
      <div className="VerifiedUserCheck__spancer" />
      <div className="VerifiedUserCheck__contentLogo">
        <div className="VerifiedUserCheck__logo">
          <Loader />
          <SimpaText />
        </div>
        <div className="VerifiedUserCheck__note">
          <Paragraph className="VerifiedUserCheck__noteText" size="l">
            Sit tight, we’re loading Simpa
          </Paragraph>
        </div>
      </div>
      <div className="VerifiedUserCheck__powerBy">
        <PoweredBy />
      </div>
    </section>
  );
};
