import * as yup from 'yup';

export const signupSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>]).{8,}$/,
      "Try adding symbols or uncommon words. Don't reuse a password from another site."
    ),
});

export const phoneNumberVerifiction = yup.object().shape({
  verify_code: yup.string().required(),
})

export const signinSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const forgotPasswordSchema = yup.object().shape({
  password: yup.string().matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>]).{8,}$/,
    "Try adding symbols or uncommon words. Don't reuse a password from another site."
  ),
  confirm_password: yup.string().matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>]).{8,}$/,
    "Try adding symbols or uncommon words. Don't reuse a password from another site."
  ),
});


export const aboutYourBusinessStep1 = yup.object().shape({
  'monthly_volume': yup.string(),
  'already_running_an_exchange': yup.string().typeError('This is a required field'),
  'setting_up_exchange_for_client': yup.string().typeError('This is a required field'),
})

export const aboutYourBusinessStep2 = yup.object().shape({
  firstName: yup.string().required().typeError('This is a required field'),
  LastName: yup.string().required().typeError('This is a required field'),
  country: yup.string().required().typeError('This is a required field'),
  code:yup.string().required().typeError('Code is required'),
  phone: yup.string().required().typeError('This is a required field'),
  city: yup.string().required().typeError('This is a required field')
})