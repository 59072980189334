import { ChangeEvent, createContext, ReactNode, useContext } from 'react';
import { CustomeSelectChangeType } from '../utils';
import { useProfile } from './useProfile';

type ProfileContextType = {
  step: number;
  state: { [key: string]: string };
  btnActive: boolean;
  saving: boolean;
  exchangeId: string;
  isCustomiseSetup: boolean;
  errorMessage: string;
  updateStep: (step: number) => void;
  savePair: () => void;
  setIsCustomiseSetup: (check: boolean) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  changeStep: (step: number) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  updateState: (name: string, value: string) => void;
  updateBtnState: (state: boolean) => void;
  handleCustomSelectChange: (event: CustomeSelectChangeType) => void;
  saveExchange: () => void;
  handleAddPayment: (name: string, isChecked: boolean, value: string) => void;
  handlePhoneVerificationCodeChange: (value: string) => void;
  savePaymentMethod: () => void;
  completeExchangeSetup: () => void;
};

const ProfileContext = createContext<ProfileContextType>(
  {} as ProfileContextType
);

export function ProfileContextProvider({ children }: { children: ReactNode }) {
  const value = useProfile();
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export function useProfileContext() {
  const context = useContext(ProfileContext);
  return context;
}
