import React, { useEffect, useState } from 'react';
import { Button, Heading, Paragraph } from '@payourse/style-guide';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import API from 'api/fusionApi';
import { Layout } from './components';
import { Checked, Spinner, VerifyError } from 'assets';

type Error = {
  statusCode: number;
};

export const EmailVerification = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const handleError = (error: Error) => {
    // loggger to log error
    console.log(error.statusCode);
  };

  const VerifyUserEmail = async () => {
    try {
      await API.verifyEmailAddress({
        verificationId: params.id,
      });
      setLoading(false);
      setIsVerified(true);
      setTimeout(() => {
        navigate('/signin', { replace: true, state: { from: location } });
      }, 1000);
    } catch (error) {
      // logger
      handleError(error as Error);
      setLoading(false);
      setIsVerified(false);
    }
  };

  useEffect(() => {
    VerifyUserEmail();
    // eslint-disable-next-line
  }, []);

  const handleRedirect = () => {
    navigate('/signup', { replace: true, state: { from: location } });
  };

  return (
    <Layout>
      <section className="Auth">
        <section className={`${!loading ? 'Auth__container' : ''}`}>
          {!loading ? (
            <div className="Auth__inboxContent">
              <div className="Auth__checkedCircle">
                {isVerified ? <Checked /> : <VerifyError />}
              </div>
              <Heading
                text={`${
                  isVerified
                    ? 'Email verified successfully'
                    : 'Unable to verify email'
                }`}
                size="xl"
                element="h1"
                className="Auth__resetHeading Auth__verifyHeading"
              />
              {!isVerified ? (
                <Paragraph size="m" className="Auth__verifyError">
                  Link has expired or does not exist
                </Paragraph>
              ) : null}
              {!isVerified ? (
                <Button
                  className="Auth__btn inbox"
                  variant="secondary"
                  type="submit"
                  disabled={false}
                  onClick={handleRedirect}
                >
                  Back to create account
                </Button>
              ) : (
                <Spinner />
              )}
            </div>
          ) : (
            <div className="Auth__inboxContent">
              <Spinner />
              <Heading
                text="verifying email..."
                size="xl"
                element="h6"
                className="Auth__resetHeading"
              />
              <div className="spacer" style={{ marginBottom: '38vh' }} />
            </div>
          )}
        </section>
      </section>
    </Layout>
  );
};
