/**
 * Utility funtion to check password strength during registration
 * @param {string} password - Password string
 * @returns {number} Password strength
 */
export const checkPasswordStrength = (password: string) => {
  const uppercaseRegex = /[A-Z]/g;
  const lowercaseRegex = /[a-z]/g;
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/g;
  const numberRegex = /[0-9]/g;

  let strength = 0;
  let isLower = false;
  let isUpper = false;
  let isSpecial = false;
  let isNumber = false;
  let isLength = false;

  if (uppercaseRegex.test(password)) {
    strength += 15;
    isUpper = true;
  }
  if (lowercaseRegex.test(password)) {
    strength += 10;
    isLower = true;
  }
  if (specialCharacterRegex.test(password)) {
    strength += 25;
    isSpecial = true;
  }
  if (numberRegex.test(password)) {
    strength += 25;
    isNumber = true;
  }
  if (password.length >= 8) {
    strength += 25;
    isLength = true;
  }

  const strengthColor =
    strength <= 50
      ? 'weak'
      : strength === 60 || strength <= 75
      ? 'okay'
      : 'strong';
  const strengthText =
    strength <= 50
      ? 'Weak'
      : strength === 60 || strength <= 75
      ? 'Weak'
      : 'Strong and secure';

  return {
    strength,
    isLower,
    isUpper,
    isSpecial,
    isNumber,
    isLength,
    strengthColor,
    strengthText,
  };
};

export const clearStorage = () => localStorage.clear();
export const getStorageValue = (key: string) => localStorage.getItem(key);

export const saveToStorage = (key: string, item: any) => {
  let value = item;
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  localStorage.setItem(key, value);
};

type code = { label: string; value: string };
export function transformCountryCode(countryCodes: string[]) {
  const transformedCountryCode = countryCodes.reduce(
    (acc: code[], item: string) => {
      let splitedCode = item.split(',');
      if (splitedCode.length > 1) {
        splitedCode.map((code) => {
          const isFound = countryCodes.some((item) => item === code);
          if (!isFound) {
            return acc.push({ label: code, value: code });
          }
          return '';
        });
      } else {
        acc.push({ label: item, value: item });
      }
      return acc;
    },
    []
  );
  return transformedCountryCode.sort(
    (a: { value: string }, b: { value: string }) =>
      parseInt(a.value) - parseInt(b.value)
  );
}
