import { ChangeEvent, useCallback, useState } from 'react';

import { useFetch } from 'hooks';
import { CustomeSelectChangeType, fields } from '../utils';
import { ApiError } from 'model/apiError';
import { useNavigate } from 'react-router';

const loadingId = 'SAVE_EXCHANGE';

export function useProfile() {
  const [step, setStep] = useState(0);
  const [loadingStates, request] = useFetch();
  const [btnActive, setBtnState] = useState(true);
  const [exchangeId, setExchangeId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCustomiseSetup, setIsCustomiseSetup] = useState(false);
  const [state, setState] = useState(() =>
    Object.values(fields).reduce((acc, field) => {
      Object.keys(field).forEach((field) => {
        acc[field] = '';
      });
      return acc;
    }, {} as ObjectType)
  );

  const incrementStep = () => setStep((s) => s + 1);
  const decrementStep = () => setStep((s) => s - 1);
  const changeStep = (step: number) => setStep(step);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = event.target;

    const _value = files ? files[0] : value;
    setState((s) => ({ ...s, [name]: _value as string }));
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const updateState = useCallback((name: string, value: string) => {
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  const handlePhoneVerificationCodeChange = (value: string) => {
    setState((s) => ({ ...s, verify_code: value }));
  };

  const updateStep = (step: number) => {
    setStep(step);
  };

  const updateBtnState = (state: boolean) => setBtnState(state);
  const handleCustomSelectChange = (event: CustomeSelectChangeType) => {
    const { name, value, Icon } = event;

    setState((previousState) => ({
      ...previousState,
      [name as string]: value,
      [value as string]: Icon || '',
    }));
  };

  type SingleValue = {
    name: string | number;
    countries: string[];
    email: string;
  };
  type Value = SingleValue[];

  type Payment = {
    name: string;
    active: string;
    banks?: any;
    exchange_id: string;
  };
  const handleAddPayment = (
    name: string,
    isChecked: boolean,
    values: string
  ) => {
    const augmentedValue: Payment = {
      name: name,
      exchange_id: exchangeId,
      active: isChecked ? 'YES' : 'NO',
      banks: name !== 'bank-transfer' ? [] : JSON.parse(values),
    };
    const payment = state?.payments as unknown as [];
    if (!payment?.length) {
      setState(
        (previousState) =>
          ({
            ...previousState,
            payments: [augmentedValue],
          } as unknown as ObjectType)
      );
    } else {
      const alreadyExist = payment.findIndex(
        (item: Payment) => item.name === name
      );
      if (alreadyExist === -1) {
        setState(
          (previousState) =>
            ({
              ...previousState,
              payments: [...payment, augmentedValue],
            } as unknown as ObjectType)
        );
      } else {
        payment.splice(alreadyExist, 1);
        setState(
          (previousState) =>
            ({
              ...previousState,
              payments: [...payment],
            } as unknown as ObjectType)
        );
      }
    }
  };

  const saveExchange = async () => {
    let data: { [key: string]: string | number | Value } = {};

    data.url = state.business_name.replaceAll(' ', '') + '.getsimpa.com';
    data.exchange_id = state.exchange_id;

    Object.keys(fields.customise).forEach((key) => {
      let value: string | number | Value = state[key];
      if (value) {
        if (fields.customise[key].type === 'number') {
          value = Number(value);
        }
      }
      data[key] = value;
    });

    const method = !!state.exchange_id ? 'PUT' : 'POST';

    await request(
      {
        data,
        loadingId: 'SAVE_PAIR',
        url: 'exchange/detail',
        method,
      },
      (data) => {
        setExchangeId(data.exchange_id);
        incrementStep();
      },
      (error) => {
        error = error as ApiError;
        setErrorMessage(error.response.data.message || error.message);
      }
    );
  };

  const savePair = async () => {
    setErrorMessage('');
    const data = {
      exchange_id: exchangeId,
      pair_type: state.pair_type,
      cryptocurrency: state.cryptocurrency,
      fiat_currency: state.fiat_currency,
      price: Number(state.price),
      minimum_amount: Number(state.minimum_amount),
      maximum_amount: Number(state.maximum_amount),
      is_active: 'YES',
      ...(state.pair_id && { pair_id: state.pair_id }),
    };

    const url = 'exchange/pair';
    const method = state?.pair_id ? 'PUT' : 'POST';
    await request(
      {
        data,
        loadingId: 'SAVE_PAIR',
        url,
        method,
      },
      () => {
        incrementStep();
      },
      (error) => {
        error = error as ApiError;
        setErrorMessage(error.response.data.message || error.message);
      }
    );
  };

  const savePaymentMethod = async () => {
    const url: string = 'exchange/payment-method';
    const isAddPaymentMethod: boolean = Boolean(Number(state?.is_addPayment));
    const method = isAddPaymentMethod ? 'PUT' : 'POST';

    let promiseArray: Promise<any>[] = [];
    const paymentMethods: [] = state?.payments as unknown as [];

    if (isAddPaymentMethod) {
      paymentMethods.map((item: Payment) => {
        let ApiCall = request(
          {
            data: { ...item, exchange_id: exchangeId },
            loadingId: 'SAVE_PAYMENT_METHOD',
            url,
            method,
          },
          (data: any) => data,
          (error: any) => error
        );
        return promiseArray.push(ApiCall);
      });
      Promise.all(promiseArray)
        .then(() => {
          incrementStep();
        })
        .catch((error) => {
          error = error as ApiError;
          setErrorMessage(error?.response?.data?.message || error?.message);
        });
    } else {
      await request(
        {
          data: {
            payment_methods: state.payments,
          },
          loadingId: 'SAVE_PAYMENT_METHOD',
          url,
          method,
        },
        () => {
          incrementStep();
        },
        (error) => {
          error = error as ApiError;
          setErrorMessage(error?.response?.data?.message || error?.message);
        }
      );
    }
  };

  const navigate = useNavigate();
  const completeExchangeSetup = () => {
    request(
      {
        data: {
          exchange_id: exchangeId,
        },
        url: 'exchange/complete-setup',
        method: 'PUT',
      },
      () => {
        navigate('/setup-completed');
      },
      (error) => {
        error = error as ApiError;
        setErrorMessage(error.response.data.message || error.message);
      }
    );
  };

  return {
    step,
    state,
    savePair,
    btnActive,
    exchangeId,
    updateStep,
    changeStep,
    updateState,
    handleChange,
    errorMessage,
    saveExchange,
    decrementStep,
    incrementStep,
    updateBtnState,
    isCustomiseSetup,
    handleAddPayment,
    savePaymentMethod,
    handleSelectChange,
    setIsCustomiseSetup,
    completeExchangeSetup,
    handleCustomSelectChange,
    saving: loadingStates[loadingId],
    handlePhoneVerificationCodeChange,
  };
}
