import { FC } from 'react';
import { Paragraph } from '@payourse/style-guide';
import { ReactComponent as Error } from 'assets/error.svg';
import { ReactComponent as Success } from 'assets/success.svg';

const Alert: FC<{ message: string; isError: boolean }> = ({
  message = 'Error',
  isError = false,
}) => {
    const appededClassName = isError ? '' : '-success'
  return (
    <div className={`Auth__alert${appededClassName}`}>
      {isError ? <Error /> : <Success />}
      <Paragraph size="m" className={`Auth__alertText${appededClassName}`}>
        {message}
      </Paragraph>
    </div>
  );
};

export default Alert;
