import { Fragment } from 'react';
import { BTC, BUSD, CUSD, DASH, DOTS, ETH, IDR, NGN, USDT } from 'assets';

export function AssetLogo({ asset }: { asset: string }) {
  switch (asset) {
    case 'BTC':
      return <BTC />;

    case 'USDT':
      return <USDT />;

    case 'NGN':
      return <NGN />;

    case 'BUSD':
      return <BUSD />;

    case 'CUSD':
      return <CUSD />;

    case 'DASH':
      return <DASH />;

    case 'ETH':
      return <ETH />;

    case 'DOTS':
    case 'PHP':
    case 'VND':
      return <DOTS />;

    case 'IDR':
      return <IDR />;

    default:
      return <Fragment>{asset}</Fragment>;
  }
}
