import { useLayoutEffect, useRef, useState } from 'react';
import { Avatar, Paragraph } from '@payourse/style-guide';
import { Icon } from '@blueprintjs/core';
import { IconButton } from 'components/shared';
import { Share, Copy, Bell, User, Logout } from 'assets';
import { useClickOut, useClipBoard } from 'hooks';
import { useAuth } from 'components/Auth/hooks';
import { userProfileDetail } from 'model/profile';
import { useExchangeContext } from 'contexts/ExchangeContext';
import { useUserVerificationState } from 'components/ProfileSetup/hooks/useUserVerificationState';

function HeaderComponents() {
  const {
    exchange: { url },
  } = useExchangeContext();
  const { firstName, lastName } = useUserVerificationState();

  return (
    <div className="HeaderComponents">
      <Url url={url || ''} />
      <Notification count={3} />
      <AvatarDropdown
        userInfo={{
          firstName,
          lastName,
          acronym: `${firstName[0]}${lastName[0]}`,
        }}
      />
    </div>
  );
}

function Url({ url }: { url: string }) {
  const { isCopied, copyToClipboard } = useClipBoard();

  const openInNewTab = (url: string) => {
    const formatedUrl = url.includes('http') ? url : `https://${url}`;
    const newWindow = window.open(formatedUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="Url">
      <div className="Url__url">
        <Paragraph size="s" weight="semi-bold">
          {url}
        </Paragraph>
      </div>
      <Tooltip content="View exchange">
        <div className="Url__icon Url__icon--view">
          <IconButton onClick={() => openInNewTab(url)}>
            <Share />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content={isCopied ? 'Link copied' : 'Click to copy link'}>
        <div className="Url__icon Url__icon--copy">
          <IconButton onClick={() => copyToClipboard(url)}>
            <Copy />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
}

function Notification({ count }: { count: number }) {
  return (
    <div className="Notification">
      <Bell />
      <span>{count}</span>
    </div>
  );
}

function AvatarDropdown({
  userInfo,
}: {
  userInfo: Pick<userProfileDetail, 'acronym' | 'lastName' | 'firstName'>;
}) {
  const { clickOutRef, isVisible, setIsVisible } =
    useClickOut<HTMLDivElement>(false);
  const { logoutUser } = useAuth();

  return (
    <div className="AvatarDropdown">
      <button
        className="AvatarDropdown__wrapper"
        onClick={() => setIsVisible(true)}
      >
        <div className="AvatarDropdown__avatar">
          <Avatar
            color="green"
            text={`${userInfo.acronym}`}
            size="medium"
            fill="subtle"
          />
        </div>
        <Paragraph size="m" weight="bold">
          {userInfo.firstName}
        </Paragraph>
        <Icon icon="caret-down" color="#696F8C" size={19} />
      </button>
      {isVisible ? (
        <div className="AvatarDropdown__content" ref={clickOutRef}>
          <div className="AvatarDropdown__username">
            <div className="AvatarDropdown__avatar">
              <Avatar
                color="green"
                text={`${userInfo.acronym}`}
                size="medium"
                fill="subtle"
              />
            </div>

            <Paragraph size="m" weight="bold">
              {userInfo.firstName} {userInfo.lastName}
            </Paragraph>
          </div>
          <ul className="AvatarDropdown__nav">
            <li>
              <User /> Profile
            </li>
            <li onClick={logoutUser}>
              <Logout /> Log out
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
}

function Tooltip({
  children,
  content,
}: {
  children: React.ReactNode;
  content: string;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [right, setRight] = useState(0);

  useLayoutEffect(() => {
    const containerWidth =
      containerRef.current?.getBoundingClientRect().width || 0;
    const width = contentRef.current?.getBoundingClientRect().width || 0;
    setRight((width - containerWidth) / 2);
  }, [content]);

  return (
    <div className="Tooltip" ref={containerRef}>
      {children}
      <span
        ref={contentRef}
        className="Tooltip__content"
        style={{ right: right ? `-${right}px` : 0 }}
      >
        {content}
      </span>
    </div>
  );
}

export default HeaderComponents;
