import './Table.scss';

interface TableProps {
  children: React.ReactNode;
  className?: string;
}

interface TableHeaderProps {
  className?: string;
  headers: string[];
}

export function Table({ children, className = '' }: TableProps) {
  return <table className={`CustomTable ${className}`}>{children}</table>;
}

export function TableHeader({ headers, className = '' }: TableHeaderProps) {
  return (
    <thead className={`CustomTable__header ${className}`}>
      <tr>
        {headers.map((header) => {
          return <th key={header}>{header}</th>;
        })}
      </tr>
    </thead>
  );
}

export function TableBody({ children, className = '' }: TableProps) {
  return (
    <tbody className={`CustomTable__body ${className} `}>{children}</tbody>
  );
}

export function TableFooter({ children }: TableProps) {
  return <div className="CustomTable__footer">{children}</div>;
}
