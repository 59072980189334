import { ButtonHTMLAttributes } from 'react';
import './IconButton.scss';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export function IconButton({
  children,
  onClick,
  className = '',
  ...props
}: IconButtonProps) {
  return (
    <button className={`IconButton ${className}`} onClick={onClick} {...props}>
      {children}
    </button>
  );
}
