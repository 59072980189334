import { useCallback, useEffect, useState } from 'react';
import { useFetch } from 'hooks';
import { ApiError } from 'model/apiError';

export const useUserVerificationState = () => {
  const FetchRequest = useFetch();
  const request = FetchRequest[1];
  const [fetching, setFetching] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetails, setUserDetails] = useState<{
    isPhoneNumberVerified: boolean;
    kycCompleted: string;
    userId: string;
    firstName: string;
    lastName: string;
  }>({
    isPhoneNumberVerified: false,
    kycCompleted: '',
    userId: '',
    firstName: '',
    lastName: '',
  });

  const geetUserInformation = useCallback(async () => {
    request(
      {
        url: '/user',
        method: 'GET',
      },
      (response) => {
        const {
          phone_verified: isPhoneNumberVerified,
          kyc_completed: kycCompleted,
          user_id: userId,
          first_name: firstName,
          last_name: lastName,
        } = response;
        setUserDetails({
          isPhoneNumberVerified,
          kycCompleted,
          userId,
          firstName,
          lastName,
        });
        setFetching(false);
      },
      (error) => {
        error = error as ApiError;
        setErrorMessage(error?.response?.data?.message || error?.message);
        setFetching(false);
      }
    );
    // eslint-disable-next-line
  }, [refetch]);

  useEffect(() => {
    geetUserInformation();
  }, [geetUserInformation]);

  return {
    fetching,
    setRefetch,
    errorMessage,
    ...userDetails,
  };
};
