import classNames from 'classnames';
import Steps, { Step } from 'rc-steps';
import { Icon } from '@blueprintjs/core';
import 'rc-steps/assets/index.css';
import './Steps.scss';

function StepIcon({ status, node }: { status: string; node: React.ReactNode }) {
  const className = classNames({
    StepIcon: true,
    [`StepIcon--${status}`]: !!status,
  });

  return <div className={className}>{node}</div>;
}

interface StepsProps {
  step: number;
  changeStep: () => void;
  initialStep?: number;
}

export function StepsComponent({
  step,
  changeStep,
  initialStep = 0,
}: StepsProps) {
  return (
    <Steps
      icons={{
        finish: <Icon icon="tick" color="#52bd94" size={9} />,
        error: null,
      }}
      size="default"
      initial={initialStep}
      current={step}
      direction="vertical"
      stepIcon={StepIcon}
    >
      <Step title="Customise" onStepClick={changeStep} />
      <Step title="Add Pair" onStepClick={changeStep} />
      <Step title="Add Payout Method(s)" onStepClick={changeStep} />
      <Step title="Review" onStepClick={changeStep} />
    </Steps>
  );
}
