import axios from 'axios';
import {
  ChangeEvent,
  DragEvent,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';

const fileTypes = new Set(['svg', 'jpeg', 'jpg', 'gif', 'png']);
export type UploadStatus = 'not-started' | 'in-progress' | 'success' | 'error';
const cloudName = 'payourse-technologies-inc';

type Config = {
  acceptedFileTypes?: Set<string>;
  sizeLimit?: number;
  folder?: string;
};

export function useFileUpload({
  folder,
  sizeLimit = 5242880,
  acceptedFileTypes = fileTypes,
}: Config) {
  const [error, setError] = useState('');
  const [files, setFile] = useState<FileList | []>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('not-started');
  const fileRef = useRef<HTMLInputElement>(null);

  const url = files[0] ? URL.createObjectURL(files[0]) : '';
  const clearFile = () => setFile([]);
  const triggerFileInput = () => fileRef.current?.click();

  const handleDragEvent = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDropEvent = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const files = event.dataTransfer.files;
    setFile(files);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setFile(files || []);
  };

  const getError = useCallback(() => {
    if (!files[0]) return;

    const [, extension] = files[0].type.split('/');
    let error = '';

    if (files[0].size > sizeLimit) {
      error = 'File size is above the specified limit';
    }

    if (!acceptedFileTypes.has(extension.toLowerCase())) {
      error = 'File format is not supported';
    }

    setError(error);
  }, [files, sizeLimit, acceptedFileTypes]);

  const uploadFile = useCallback(
    async (fileName?: string, callback?: (response: any) => void) => {
      try {
        setUploadStatus('in-progress');

        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        const data = new FormData();
        data.append('file', files[0]);
        data.append('cloud_name', cloudName);
        data.append('folder', `simpa/${folder}`);
        data.append('upload_preset', 'simpa_merchant');
        if (fileName) data.append('public_id', fileName);

        const response = await axios.post(url, data);

        callback?.(response);
        setUploadStatus('success');
      } catch (error) {
        setUploadStatus('error');
      }
    },
    [files, folder]
  );

  useEffect(() => {
    getError();
  }, [files.length, getError]);

  return {
    url,
    files,
    error,
    fileRef,
    uploadStatus,
    clearFile,
    triggerFileInput,
    handleDragEvent,
    handleDropEvent,
    handleFileChange,
    uploadFile,
  };
}
