export const formatMetaData = (metaData: Meta): MetaData => {
  const { current_page, total_pages, per_page, total_docs } = metaData;

  return {
    currentPage: current_page,
    totalPages: total_pages,
    perPage: per_page,
    total: total_docs,
  };
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-US', {}).format;
  return formatter(num);
};

export const shortenString = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};
