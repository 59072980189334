import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useFormik } from 'formik';
import ReactCodeInput from 'react-verification-code-input';
import { Button, Heading, Paragraph } from '@payourse/style-guide';
import { ReactComponent as Back } from 'assets/back.svg';
import { useProfileContext } from '../context';
import { phoneNumberVerifiction } from 'components/Auth/schema';
import { ApiError } from 'model/apiError';
import Alert from 'components/Auth/components/Alert';

const initialValues = {
  verify_code: '',
};

export function VerifyNumber() {
  const navigate = useNavigate();
  const [loadingStates, request] = useFetch();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<string>('')
  const { handlePhoneVerificationCodeChange, state, decrementStep } =
    useProfileContext();

  const handleChangeToSetFormikFieldValue = (value: string) => {
    handlePhoneVerificationCodeChange(value);
    setFieldValue('verify_code', value);
  };

  const handleVerification = async () => {
    const data = {
      code: state.verify_code,
    };
      await request(
        {
          data,
          loadingId: 'VERIFY_PHONE_NUMBER',
          url: '/validate-phone/check',
          baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
          method: 'POST',
        },
        () => {
          navigate('/phone-verification-done');
        },
        (error) => {
          setErrorMessage(error.response.data.message || error.message);
        }
      );
  };

  const { handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit: handleVerification,
    validationSchema: phoneNumberVerifiction,
  });

  const handleResendVerificationCode = async () => {
    setSuccessMessage('')
      await request(
        {
          data: {},
          loadingId: 'RESEND_CODE',
          url: '/validate-phone/resend',
          baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
          method: 'POST',
        },
        () => {
          setSuccessMessage('Code successfully resent to provided phone number')
        },
        (error) => {
          error = error as ApiError
          setErrorMessage(error?.response?.data?.message || error?.message)
        }
      );
  
  };

  return (
    <section className="Auth__container">
      <div className="Auth__back" onClick={decrementStep}>
        <Back />
        <Paragraph size="m" className="Auth__backText">
          Back
        </Paragraph>
      </div>

      <div className="Auth__inboxContent">
        <Heading
          text="Enter 6-digit code"
          size="xl"
          element="h1"
          className="Auth__inboxHeading code"
        />
        <Paragraph size="m" className="Auth__centerText">
          Please enter the 6-digit code sent to your phone number
        </Paragraph>
        <form onSubmit={handleSubmit}>
          <ReactCodeInput
            className="Auth__numberVerfiy"
            onChange={handleChangeToSetFormikFieldValue}
            onComplete={handleChangeToSetFormikFieldValue}
            required={true}
          />
          {!!errorMessage && <Alert isError={true} message={errorMessage} />}
          <Button
            className="Auth__btn"
            variant="secondary"
            type="submit"
            disabled={
              loadingStates.VERIFY_PHONE_NUMBER || loadingStates.RESEND_CODE
            }
          >
            Verify
          </Button>
          <p>
            Didn’t get it?{' '}
            <span
              className="Auth__container--resendCode"
              onClick={handleResendVerificationCode}
            >
              Click resend code
            </span>
          </p>
          {successMessage ? <Alert message={successMessage} isError={false} /> : null}
        </form>
      </div>
    </section>
  );
}
