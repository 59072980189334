import { useState } from 'react';
import {
  useGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useNavigate } from 'react-router-dom';

import FusionAPI from 'api/fusionApi';
import { useAuth } from './useAuth';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
const applicationId = process.env.REACT_APP_APPLICATION_ID;
const googleId = process.env.REACT_APP_GOOGLE_ID;

export function useGoogleAuth() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { completeAuth } = useAuth();

  const handleSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const googleLoginResponse = response as GoogleLoginResponse;

    try {
      const {
        tokenObj: { id_token },
      } = googleLoginResponse;

      const response = await FusionAPI.identityProviderLogin({
        applicationId,
        identityProviderId: googleId,
        data: {
          token: id_token,
          redirect_uri: `${window.location.origin}/oauth2/callback`,
        },
      });

      if (response.wasSuccessful()) {
        const { token, refreshToken } = response.response;
        completeAuth(token, refreshToken);
        navigate('/');
      } else {
        setError('Google authentication failed');
      }
    } catch (error) {
      setError('Google authentication failed');
    }
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  const { signIn } = useGoogleLogin({
    clientId,
    onSuccess: handleSuccess,
    onFailure: handleError,
  });

  return { signIn, error };
}
