import axios, { AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { useRef } from 'react';
import fusionAuth from 'api/fusionApi';
import { useAuth } from 'components/Auth/hooks';
import { getStorageValue } from 'utils';

const baseURL = process.env.REACT_APP_SIMPA_BASE_URL;

export function useCustomAxios() {
  const { completeAuth, logoutUser } = useAuth();
  const accessToken = getStorageValue('accessToken');
  const axiosRef = useRef<AxiosInstance>(
    axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken || ''}`,
        'Content-Type': 'application/json',
      },
    })
  );

  // interceptor to handle authentication error
  axiosRef.current.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (error.config && error?.response?.status === 401) {
        const oldRefreshToken = getStorageValue('refreshToken');

        if (oldRefreshToken) {
          try {
            const tokenResponse = await fusionAuth.exchangeRefreshTokenForJWT({
              token: accessToken as string,
              refreshToken: oldRefreshToken,
            });
            const { token, refreshToken } = tokenResponse.response;
            completeAuth(token, refreshToken);

            error.config.headers!.Authorization = `Bearer ${token}`;

            return axiosRef.current.request(error.config);
          } catch (error) {
            logoutUser();
          }
        } else {
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  return axiosRef.current;
}
