import { checkPasswordStrength } from 'utils';
import {
  Paragraph,
  PasswordStrengthIndicator,
} from '@payourse/style-guide';

export const PasswordStrengthCheck = ({
  password
}: {
  password: string
}) => {

  const { isLength, isLower, isNumber, isSpecial, isUpper, strengthColor, strengthText } =
  checkPasswordStrength(password);
  
  return (
    <>
    <div className="Auth__indicatorWrapper">
      <Paragraph size="m" className="Auth__indicatorStatus">
        Password strength:{' '}
        <span className={`Auth__${strengthColor}`}>
          {strengthText}
        </span>
      </Paragraph>
      <PasswordStrengthIndicator
        className="Auth__strength-indicator"
        strength={checkPasswordStrength(password).strength}
      />
    </div>
    <div className="Auth__validatorList">
      <ul>
        <li className={`Auth__${isLower ? 'valid' : 'invalid'}`}>
          One lowercase letter
        </li>
        <li className={`Auth__${isUpper ? 'valid' : 'invalid'}`}>
          One uppercase letter
        </li>
        <li className={`Auth__${isSpecial ? 'valid' : 'invalid'}`}>
          One special character
        </li>
      </ul>
      <ul>
        <li className={`Auth__${isLength ? 'valid' : 'invalid'}`}>
          At least 8 characters long
        </li>
        <li className={`Auth__${isNumber ? 'valid' : 'invalid'}`}>
          One number
        </li>
      </ul>
    </div>
    </>
  );
};
