import { Paragraph } from '@payourse/style-guide';

import { GoogleIcon } from 'assets';

export function GoogleButton({
  signIn,
  text,
}: {
  signIn: () => void;
  text: string;
}) {
  return (
    <button className="Auth__google" onClick={signIn}>
      <GoogleIcon />
      <Paragraph size="l" weight="bold" className="Auth__continue">
        {text}
      </Paragraph>
    </button>
  );
}
