import { Icon } from '@blueprintjs/core';
import { Heading } from '@payourse/style-guide';
import { IconButton } from 'components/shared';
import HeaderComponents from './HeaderComponents';

import '../styles.scss';
import { useLocation } from 'react-router-dom';

interface HeaderProps {
  toggleSideBar: () => void;
}

export function Header({ toggleSideBar }: HeaderProps) {
  const location = useLocation();

  const headerMap: Record<string, string> = {
    '/dashboard': 'Exchange Overview',
    '/orders': 'Your Orders',
    '/balances': 'Balances',
    '/pairs': 'Pairs',
  };

  return (
    <header className="Header">
      <div className="Header__wrapper">
        <div className="Header__menu">
          <IconButton onClick={toggleSideBar}>
            <Icon icon="menu" color="#696F8C" />
          </IconButton>
        </div>
        <div className="Header__content">
          <Heading text={headerMap[location.pathname]} size="xl" element="h1" />
          <HeaderComponents />
        </div>
      </div>
    </header>
  );
}
