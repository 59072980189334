import React, { useEffect } from 'react';
import { Heading } from '@payourse/style-guide';
import { ReactComponent as Checked } from 'assets/circle_checked.svg';
import { Layout } from 'components/Auth/components';
import { useNavigate } from 'react-router';
import { Spinner } from 'assets';



export function VerificationDone(){  
  const navigate = useNavigate()
  useEffect( () => {
    setTimeout( () => {
      navigate('/dashboard')
    }, 1000)
    // eslint-disable-next-line
  }, [])
    return (
      <Layout>
          <section className='Auth__container'>
            <div className='Auth__inboxContent'>
                <Checked />
                <Heading element='h1' size='m' className='Auth__profileSet' 
                text="Phone number verification successful " />
            <Spinner />
            </div>
          </section>
      </Layout>
    )
}
