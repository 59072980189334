import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Heading, Paragraph } from '@payourse/style-guide';
import { ReactComponent as Checked } from 'assets/circle_checked.svg';
import { ReactComponent as PoweredBy } from 'assets/Poweredbypayourselogo.svg';
import { ReactComponent as Simpa } from 'assets/simpa.svg';
import './styles/Auth.scss' 

export const ResetPasswordSent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state) {
    window.location.href = '/forgot-password';
  }

  const handleRedirectToLogin = () => {
    navigate('/signin');
  };

  return (
    <main className="SignUpDone Layout">
      <section className="SignUpDone__Simpa">
        <Simpa />
      </section>
      <section className="Auth">
        <section className="Auth__container">

          <div className="Auth__inboxContent">
            <div className="Auth__checkedCircle">
              <Checked />
            </div>
            <Heading
              text="Password reset successful"
              size="xl"
              element="h1"
              className="Auth__resetHeading"
            />
            <Paragraph size="m" className="Auth__centerText">
              We’ve sent an email for your records.
            </Paragraph>

            <Button
              className="Auth__btn inbox"
              variant="secondary"
              type="submit"
              disabled={false}
              onClick={handleRedirectToLogin}
            >
              Log in
            </Button>
          </div>
        </section>
      </section>
      <section className="SignUpDone__PoweredBy">
        <PoweredBy />
      </section>
    </main>
  );
};
