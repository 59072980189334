import { Fragment } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconButton } from 'components/shared';
import { colors } from 'utils/colors';
import './Table.scss';

interface PaginationProps {
  view?: 'mobile' | 'desktop';
  metaData: MetaData;
  handlePageChange: (page: number) => void;
}

const Spacer = () => <Fragment>&nbsp;&nbsp;</Fragment>;

export function Pagination({
  handlePageChange,
  metaData,
  view = 'desktop',
}: PaginationProps) {
  const { currentPage = 0, totalPages = 0, perPage = 0, total = 0 } = metaData;
  const availableItems =
    total < perPage || currentPage === totalPages
      ? total
      : currentPage * perPage;

  return (
    <div className="Pagination">
      <div>
        SHOWING:
        <Spacer />
        <span>
          {availableItems}/{total}
        </span>
      </div>

      <div>
        {view === 'desktop' ? (
          <span>
            <Spacer />
            <Spacer />
            Rows per page: {perPage}
            <Spacer />
            <Spacer />
          </span>
        ) : null}
      </div>
      <div className="Pagination__icons">
        <IconButton
          disabled={currentPage === 1 || currentPage < 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <Icon icon="chevron-left" color={colors.dark_5} />
        </IconButton>
        <IconButton
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <Icon icon="chevron-right" color={colors.dark_5} />
        </IconButton>
      </div>
    </div>
  );
}
