import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, SideNav } from './components';
import './styles.scss';

export function Layout() {
  const [showSideBar, toggleSideBarVisibility] = useState(false);

  const toggleSideBar = (state = true) => {
    toggleSideBarVisibility(state);
  };

  return (
    <div className="Layout">
      <Header toggleSideBar={() => toggleSideBar()} />
      <div className="Layout__wrapper">
        <SideNav isOpen={showSideBar} toggleSideBar={toggleSideBar} />
        <section className="Layout__content">
          <Outlet />
        </section>
      </div>
    </div>
  );
}
