import { Button, Heading, SelectField, Text } from '@payourse/style-guide';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { fields } from '../utils';
import Container from './Container';
import { ApiError } from 'model/apiError';
import { useProfileContext } from '../context';
import Alert from 'components/Auth/components/Alert';
import { RadioYesNoButton } from './RadioYesNoButton';
import { ArrowLeft, ArrowRight } from 'assets';
import { useAuth } from 'components/Auth/hooks';
import { aboutYourBusinessStep1 } from 'components/Auth/schema';
import './styles.scss';

type AboutStep = {
  already_running_an_exchange: boolean | undefined;
  monthly_volume: string;
  setting_up_exchange_for_client: boolean | undefined;
};

const initialValues: AboutStep = {
  monthly_volume: '',
  already_running_an_exchange: undefined,
  setting_up_exchange_for_client: undefined,
};

export function About() {
  const { logoutUser } = useAuth();
  const [loadingStates, request] = useFetch();
  const [ErrorMessage, setErrorMessage] = useState('');
  const [aboutStep, setAboutStep] = useState<AboutStep>({
    already_running_an_exchange: undefined,
    monthly_volume: '',
    setting_up_exchange_for_client: undefined,
  });
  const { state, incrementStep, handleSelectChange, handleChange, step } =
    useProfileContext();

  const handleError = (error: ApiError) => {
    const { message, response } = error;
    setErrorMessage(response.data.message || message);
  };

  useEffect(() => {
    const getBusinessInformation = async () => {
      try {
        await request(
          {
            data: {},
            loadingId: 'BUSINESS_INFROMATION',
            url: '/business-information',
            baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
            method: 'GET',
          },
          (data) => {
            // response data here
            if (Object.keys(data).length) {
              delete data.user_email;
              setAboutStep(data);
            }
          },
          (error) => {
            if (error.response.status !== 404) {
              setErrorMessage(error.response.data.message || error.message);
            }
          }
        );
      } catch (error) {
        handleError(error as ApiError);
      }
    };
    getBusinessInformation();
    // eslint-disable-next-line
  }, []);

  const handleNextStep = async () => {
    setErrorMessage('');
    const isNotNull =
      aboutStep.setting_up_exchange_for_client === undefined &&
      aboutStep.already_running_an_exchange === undefined;
    if (isNotNull) {
      return incrementStep();
    }
    try {
      const data = aboutStep;
      await request(
        {
          data,
          loadingId: 'ABOUT_BUSINESS',
          url: '/business-information',
          baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
          method: 'PUT',
        },
        () => {
          incrementStep();
        },
        (error) => {
          const renamedError: string[] = error.response.data.data.errors;
          setErrorMessage(renamedError[0]);
        }
      );
    } catch (error) {
      handleError(error as ApiError);
    }
  };
  const { handleSubmit, errors, setFieldValue } = useFormik({
    initialValues,
    validationSchema: aboutYourBusinessStep1,
    onSubmit: handleNextStep,
  });

  const handleAlreadyRunningExchange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAboutStep((previousState) => ({
        ...previousState,
        already_running_an_exchange: Boolean(Number(event.target.value)),
      }));
      setFieldValue(event.target.name, event.target.value, true);
      handleChange(event);
    },
    [setAboutStep, handleChange, setFieldValue]
  );

  const handleSettingUpExchange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAboutStep((previousState) => ({
        ...previousState,
        setting_up_exchange_for_client: Boolean(Number(event.target.value)),
      }));
      setFieldValue(event.target.name, event.target.value, true);
      handleChange(event);
    },
    [setAboutStep, setFieldValue, handleChange]
  );

  const handleComponentSelectChange = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    setFieldValue(event.target.name, event.target.value, true);
    setAboutStep((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    handleSelectChange(event);
  };

  return (
    <Container>
      <div className="Profile__description">
        <div className="Profile__progressContainer">
          <span className="Profile__progress">Step {step + 1} of 2</span>
        </div>
        <Heading
          element="h3"
          size="xl"
          text="Tell us about your business"
          className="Profile__title"
        />
        <Text
          size="m"
          weight="normal"
          text="Before you can create an exchange on Simpa, we need to learn more about you and your business. We collect this information to comply with requirements and craft a better experience for you."
          className="Profile__descriptionText"
        />
      </div>
      {ErrorMessage ? <Alert message={ErrorMessage} isError={true} /> : null}
      <form onSubmit={handleSubmit}>
        <div>
          <Text
            size="m"
            weight="normal"
            text="Are you already running an exchange?"
          />
          <RadioYesNoButton
            name="already_running_an_exchange"
            idYes="exchange-Yes"
            idNo="exchange-no"
            placeholder=""
            error={
              errors['already_running_an_exchange']
                ? errors['already_running_an_exchange']
                : ''
            }
            value={
              aboutStep.already_running_an_exchange !== undefined
                ? Number(aboutStep.already_running_an_exchange)
                : undefined
            }
            onChange={handleAlreadyRunningExchange}
          />
        </div>
        {aboutStep?.already_running_an_exchange
          ? Object.values(fields.about).map((field) => {
              if (field.type === 'select') {
                return (
                  <SelectField
                    {...field}
                    options={field.options!}
                    key={field.name}
                    value={
                      aboutStep?.monthly_volume
                        ? aboutStep?.monthly_volume
                        : state[field.name]
                    }
                    className="Profile__field Select"
                    onChange={handleComponentSelectChange}
                  />
                );
              }
              return null;
            })
          : null}
        <div>
          <Text
            size="m"
            weight="normal"
            text="Are you setting up an exchange platform for a client?"
          />
          <RadioYesNoButton
            name="setting_up_exchange_for_client"
            idYes="isClient-Yes"
            idNo="isClient-no"
            error={
              errors['setting_up_exchange_for_client']
                ? errors['setting_up_exchange_for_client']
                : ''
            }
            value={
              aboutStep.setting_up_exchange_for_client !== undefined
                ? Number(aboutStep?.setting_up_exchange_for_client)
                : undefined
            }
            onChange={handleSettingUpExchange}
          />
        </div>

        <div className="Profile__actionsContainer">
          <Button
            className="Auth__btnContained"
            variant="outline"
            onClick={logoutUser}
          >
            <ArrowLeft /> Back to login
          </Button>
          <div className="Profile__actions">
            <Button
              className="Auth__btnContained"
              variant="outline"
              onClick={incrementStep}
            >
              Skip
            </Button>
            <Button
              type="submit"
              className="Auth__btnContained"
              variant="secondary"
              disabled={loadingStates.ABOUT_BUSINESS}
            >
              Continue <ArrowRight stroke="#ffffff" />
            </Button>
          </div>
        </div>
      </form>
    </Container>
  );
}
