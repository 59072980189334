import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Heading,
  Paragraph,
  Password,
  TextField,
} from '@payourse/style-guide';

import { Layout, GoogleButton, PasswordStrengthCheck } from './components';
import Alert from './components/Alert';

import fusionApi from 'api/fusionApi';
import { IError, ISignup } from 'model/signup';
import { signupSchema } from './schema';
import { useGoogleAuth } from './hooks';
import './styles/Auth.scss';

const initialValues: ISignup = {
  email: '',
  password: '',
};

export function SignUp() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { error: googleAuthError, signIn } = useGoogleAuth();

  const handleError = (error: IError) => {
    const { exception, statusCode } = error;
    if (statusCode === 400) {
      exception?.fieldErrors['user.email']?.map((item) =>
        setErrorMessage(item.message)
      );
      setIsError(true);
    } else {
      setErrorMessage('Something went wrong, please try again');
    }
  };

  const handleSubmitRequest = async () => {
    try {
      setIsError(false);
      await fusionApi.register('', { user: values, registration: {
        applicationId: `${process.env.REACT_APP_APPLICATION_ID}`
      } });
      navigate('/signup-done', { state: { email: values.email } });
    } catch (error) {
      handleError(error as IError);
    }
  };

  const { values, errors, getFieldProps, touched, handleSubmit } = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: handleSubmitRequest,
  });

  const isDisabled = !!(errors.password || errors.email || errors.password);
  const hasError = isError || Boolean(googleAuthError);

  return (
    <Layout>
      <div className="Auth__container">
        <Heading
          text="Create your Simpa account"
          size="xl"
          element="h1"
          className="Auth__title"
        />
        <GoogleButton text="Continue with Google" signIn={signIn} />
        <Paragraph size="m" className="Auth__email">
          Or use your email
        </Paragraph>

        {hasError && (
          <Alert message={errorMessage || googleAuthError} isError={hasError} />
        )}

        <div>
          <form onSubmit={handleSubmit}>
            <TextField
              className="Auth__field"
              label="Your email"
              id="email"
              data-testid="email"
              placeholder=""
              error={touched.email ? errors.email : ''}
              {...getFieldProps('email')}
            />
            <Password
              className="Auth__field"
              label="Set password"
              type="password"
              id="password"
              data-testid="password"
              autoComplete="current-password"
              error={
                !values.password && touched.password ? errors.password : ''
              }
              {...getFieldProps('password')}
            />

            {!!values.password && errors.password ? (
              <PasswordStrengthCheck password={values.password} />
            ) : null}

            <Paragraph size="m" className="Auth__term">
              By clicking the “Create my account” button, you agree to our{' '}
              <span className="Auth__term-highlight">Terms of Service</span> and
              have read and understood{' '}
              <span className="Auth__footerNote-term">Privacy Policy</span>
            </Paragraph>

            <Button
              className="Auth__btn"
              variant="secondary"
              type="submit"
              disabled={isDisabled}
            >
              Create my account
            </Button>

            <Paragraph size="m" className="Auth__footNote">
              Already have a Simpa account?
              <NavLink to="/signin" className="Auth__footNote-highlight">
                {' '}
                Log In
              </NavLink>
            </Paragraph>
          </form>
        </div>
      </div>
    </Layout>
  );
}
