import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ForgotPassword,
  ResetPasswordSent,
  ResetPassword,
  SignIn,
  SignUp,
  SignupDone,
  ForgotPasswordLinkSent,
  EmailVerification,
} from 'components/Auth';
import { AuthProvider } from 'components/Auth/components';
import ProtectedRoutes from 'ProtectedRoutes';

import './App.scss';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup-done" element={<SignupDone />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify/:id" element={<EmailVerification />} />
          
          <Route
            path="/forgot-password-link-sent"
            element={<ForgotPasswordLinkSent />}
          />
          <Route path="/email-sent" element={<ResetPasswordSent />} />
          <Route path="/*" element={<ProtectedRoutes />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
