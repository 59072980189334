import { useRef, useCallback, useReducer } from 'react';
import { AxiosRequestConfig } from 'axios';
import { ApiError } from 'model/apiError';
import { useCustomAxios } from 'hooks';

type RequestType = 'idle' | 'loading' | 'success' | 'error';

type State<T> = {
  error: ApiError;
  requestState: RequestType;
  response: T;
};

export function useCustomFetch<T>() {
  const abortRef = useRef<AbortController>();
  const customAxios = useCustomAxios();
  const [state, setState] = useReducer(
    (state: State<T>, payload: Partial<State<T>>) => ({
      ...state,
      ...payload,
    }),
    {
      error: {} as ApiError,
      requestState: 'idle',
      response: {} as T,
    }
  );

  const fetch = useCallback(
    async (
      { url, data, method = 'GET', ...config }: AxiosRequestConfig,
      callback?: (data: T) => void
    ) => {
      try {
        setState({ requestState: 'loading' });
        if (abortRef.current) abortRef.current.abort(); // cancel pending requests
        const { signal } = (abortRef.current = new AbortController());

        let options = {
          url,
          method,
          signal,
          ...(data && { data: JSON.stringify(data) }),
          ...config,
        };

        const response = await customAxios(options);
        setState({
          requestState: 'success',
          response: response.data.data as T,
        });
        callback?.(response.data.data);
      } catch (error: any) {
        if (error?.name !== 'CanceledError') {
          setState({ requestState: 'error', error });
        }
      }
    },
    [customAxios]
  );

  return {
    fetch,
    isIdle: state.requestState === 'idle',
    isLoading: state.requestState === 'loading',
    isError: state.requestState === 'error',
    isSuccess: state.requestState === 'success',
    ...state,
  };
}
