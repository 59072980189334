import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import { Heading, Button } from '@payourse/style-guide';
import { CaretRight } from 'assets';
import { useClickOut } from 'hooks';
import './Dialog.scss';

interface DialogProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  dialogClassName?: string;
  contentClassName?: string;
  title: string;
}

export function Dialog({
  open,
  title,
  children,
  onClose,
  dialogClassName = '',
  contentClassName = '',
}: DialogProps) {
  const { clickOutRef, isVisible } = useClickOut(open, onClose);

  const dialogClassNames = classNames({
    Dialog: true,
    'Dialog--visible': isVisible,
    [dialogClassName]: !!dialogClassName,
  });

  const contentClassNames = classNames({
    Dialog__content: true,
    'Dialog__content--visible': isVisible,
    [contentClassName]: !!contentClassName,
  });

  return (
    <div className={dialogClassNames}>
      <div className={contentClassNames} ref={clickOutRef}>
        <div className="Dialog__header">
          <div className="Dialog__title">
            <Button
              variant="text"
              onClick={onClose}
              className="Dialog__close-mobile"
            >
              <CaretRight />
            </Button>
            <Heading text={title} element="h2" size="m" />
          </div>
          <Button
            className="Dialog__close-btn"
            variant="text"
            onClick={onClose}
          >
            <Icon icon="cross" color="#47b881" size={14} />
            Close
          </Button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
