import { NavLink } from 'react-router-dom';

import { useClickOut } from 'hooks';
import { Simpa, Home, Orders, Balances, Pairs } from 'assets';

import '../styles.scss';

interface SideNavProps {
  isOpen: boolean;
  toggleSideBar: (state: boolean) => void;
}

export function SideNav({ isOpen, toggleSideBar }: SideNavProps) {
  const className = ({ isActive }: { isActive: boolean }) =>
    isActive ? 'SideNav__item--active' : '';

  const { isVisible, clickOutRef } = useClickOut<HTMLDivElement>(isOpen, () =>
    toggleSideBar(false)
  );

  return (
    <nav
      className={`SideNav ${
        isVisible ? 'SideNav--open' : 'SideNav--collapsed'
      }`}
    >
      <div className="SideNav__backdrop" />
      <div ref={clickOutRef} className="SideNav__wrapper">
        <Simpa className="SideNav__logo" />
        <ul className="SideNav__nav-items">
          <li className="SideNav__item">
            <NavLink to="/dashboard" className={className}>
              <Home />
              Home
            </NavLink>
          </li>
          <li className="SideNav__item">
            <NavLink to="/orders" className={className}>
              <Orders />
              Orders
            </NavLink>
          </li>
          <li className="SideNav__item">
            <NavLink to="/pairs" className={className}>
              <Pairs />
              Pairs
            </NavLink>
          </li>
          <li className="SideNav__item">
            <NavLink to="/balances" className={className}>
              <Balances />
              Balances
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
