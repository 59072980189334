import React from 'react';
import { ReactComponent as Simpa } from 'assets/simpa.svg';
import { ReactComponent as PoweredBy } from 'assets/Poweredbypayourselogo.svg';
import '../styles/Auth.scss';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <main className='Layout'>
      <section className="Layout__Simpa">
        <Simpa />
      </section>

      <section className="Auth">{children}</section>

      <section className="Layout__PoweredBy">
        <PoweredBy />
      </section>
    </main>
  );
};

export default Layout;
