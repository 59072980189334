import {
  Button,
  Heading,
  SelectField,
  Text,
  TextField,
} from '@payourse/style-guide';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useProfileContext } from '../context';
import { fields, phoneCodes, countryCode } from '../utils';
import Container from './Container';
import Alert from 'components/Auth/components/Alert';
import { aboutYourBusinessStep2 } from '../../Auth/schema';
import { ApiError } from 'model/apiError';
import { ReactComponent as Arrow } from 'assets/ArrowRight.svg';
import { ReactComponent as BackArrow } from 'assets/ArrowLeft.svg';
import './styles.scss';

type BusinsessInformationState = {
  firstName: string;
  lastName: string;
  country: string;
  phone: string;
  city: string;
  code?: string;
};

export function BusinessInformation() {
  const {
    step,
    state,
    handleChange,
    incrementStep,
    decrementStep,
    handleSelectChange,
  } = useProfileContext();
  const initialValues: BusinsessInformationState = {
    city: state.city,
    phone: state.phone,
    code: state.code,
    country: state.country,
    lastName: state.LastName,
    firstName: state.firstName,
  };
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setselectedCountryCode] = useState<string>('');
  const [loadingStates, request] = useFetch();
  const [countryState, setCountryState] = useState<
    { label: string; value: string }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error: ApiError) => {
    const { message, response } = error;
    setErrorMessage(response?.data?.message || message);
  };

  const handleNextStep = async (values: BusinsessInformationState) => {
    setErrorMessage('');
    const trimPhoneNumber = selectedCountryCode + values.phone;
    try {
      const data = {
        city: values.city,
        country: values.country,
        last_name: values.lastName,
        phone_number: trimPhoneNumber,
        first_name: values.firstName,
      };
      const loadingId = 'BUSINESS_INFORMATION';
      const url = '/user';
      await request(
        {
          data,
          loadingId,
          url,
          baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
          method: 'PUT',
        },
        () => {
          incrementStep();
        },
        (error) => {
          setErrorMessage(error.response.data.message);
        }
      );
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const { handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    onSubmit: handleNextStep,
    validationSchema: aboutYourBusinessStep2,
  });

  const handleComponentonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.name, event.target.value, true);
    handleChange(event);
  };

  const handleComponentSelectChange = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    setselectedCountryCode(event.target.value);
    setFieldValue(event.target.name, event.target.value, true);
    handleSelectChange(event);
  };

  const handleCityChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(event.target.name, event.target.value, true);
    handleSelectChange(event);
  };

  const handleCountryState = async (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setFieldValue(event.target.name, event.target.value, true);
    const selectedCountryCode = countryCode[event.target.value];
    setselectedCountryCode(selectedCountryCode);
    setFieldValue('code', selectedCountryCode, true);
    handleSelectChange(event);
    try {
      const options = {
        url: `/cities?country=${value}`,
        loadingId: 'COUNTRY_CITIES',
        baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
      };
      await request(options, (response) => {
        const res: string[] = Array.from(
          new Set(response.cities.map((city: string) => city))
        );
        const stateOptions: { label: string; value: string }[] = res.map(
          (state: string) => ({ label: state, value: state })
        );
        setCountryState(stateOptions);
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const loadingId = 'COUNTRY_CITIES';
        const url = `/countries`;
        const options = {
          url,
          loadingId,
          baseURL: process.env.REACT_APP_SIMPA_BASE_URL,
        };
        await request(options, (response) => {
          const countriesOption = response.countries.map((country: string) => ({
            label: country,
            value: country,
          }));
          setCountries(countriesOption);
        });
      } catch (error) {
        handleError(error as ApiError);
      }
    };
    getCountries();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="Profile__description">
        <div className="Profile__progressContainer">
          <span className="Profile__progress">Step {step + 1} of 2</span>
        </div>
        <Heading
          element="h3"
          size="xl"
          text="Tell us a little more about yourself"
          className="Profile__title"
        />
        <Text
          size="m"
          weight="normal"
          text="Before you can create an exchange on Simpa, we need to learn more about you and your business. We collect this information to comply with requirements and craft a better experience for you."
          className="Profile__descriptionText"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="Profile__inputSideBySide">
          {Object.values(fields.information).map((field, index) => {
            if (field.name === 'firstName') {
              return (
                <TextField
                  {...field}
                  className="Profile__field"
                  onChange={handleComponentonChange}
                  key={field.name + index}
                  error={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                  value={state?.firstName}
                />
              );
            }
            return null;
          })}
          {Object.values(fields.information).map((field, index) => {
            if (field.name === 'LastName') {
              return (
                <TextField
                  {...field}
                  className="Profile__field"
                  onChange={handleComponentonChange}
                  key={field.name + index}
                  error={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                  value={state[field.name]}
                />
              );
            }
            return null;
          })}
        </div>

        {Object.values(fields.information).map((field, index) => {
          if (field.type === 'select') {
            return field.name === 'country' ? (
              <SelectField
                {...field}
                options={countries}
                key={field.name + index}
                value={state[field.name]}
                className="Profile__field Select"
                onChange={handleCountryState}
                error={errors.country && touched.country ? errors.country : ''}
              />
            ) : (
              <SelectField
                {...field}
                options={countryState}
                key={field.name + index}
                className="Profile__field Select"
                onChange={handleCityChange}
                error={errors.city && touched.city ? errors.city : ''}
                defaultValue={state[field.name]}
                required
              />
            );
          }
          return null;
        })}

        <div>
          <label htmlFor="" className="Profile__inputSideBySideCode-label">
            Phone number
          </label>
          <div className="Profile__inputSideBySideCode">
            {Object.values(fields.information).map((field, index) => {
              if (field.name === 'code') {
                return (
                  <Fragment key={index}>
                    <SelectField
                      {...field}
                      options={phoneCodes}
                      label=""
                      value={selectedCountryCode}
                      className="Profile__field Select"
                      onChange={handleComponentSelectChange}
                      error={errors.code && touched.code ? errors.code : ''}
                    />
                  </Fragment>
                );
              }
              return null;
            })}
            {Object.values(fields.information).map((field, index) => {
              if (field.name === 'phone') {
                return (
                  <TextField
                    {...field}
                    className="Profile__field"
                    onChange={handleComponentonChange}
                    label=""
                    name={field.name}
                    placeholder={field.placeholder}
                    key={field.name + index}
                    error={errors.phone && touched.phone ? errors.phone : ''}
                    value={state[field.name]}
                    type="text"
                    maxLength={12}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
        {!!errorMessage && <Alert isError={true} message={errorMessage} />}
        <div className="Profile__actions spacer">
          <Button
            className="Auth__btnContained"
            variant="outline"
            onClick={decrementStep}
          >
            <BackArrow /> Back
          </Button>
          <Button
            className="Auth__btnContained"
            variant="secondary"
            type="submit"
            disabled={loadingStates.BUSINESS_INFORMATION}
          >
            Next <Arrow stroke="#ffffff" />
          </Button>
        </div>
      </form>
    </Container>
  );
}
