import React from 'react';
import {
  Button, Heading, Paragraph
} from '@payourse/style-guide';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Back } from 'assets/back.svg';
import { ReactComponent as Envelope } from 'assets/envelope.svg';
import { ReactComponent as PoweredBy } from 'assets/Poweredbypayourselogo.svg';
import { ReactComponent as Simpa } from 'assets/simpa.svg';

export function ForgotPasswordLinkSent(){

  const navigate = useNavigate();
  const {state} = useLocation();

  if(!state) {
    window.location.href = '/forgot-password'    
  };

    const back = () => {
        navigate('/forgot-password')
    }  

    const handleBackToLogin = () => {
        navigate('/signin')
    }

  return (
    <main className='SignUpDone Layout'>
    <section className='SignUpDone__Simpa'>
        <Simpa />
      </section>
            <section className='Auth'>
              <section className='Auth__container'>
                <div className='Auth__back'>
                  <Back />
                  <Paragraph size='m' className="Auth__backText" onClick={back}>
                    Back 
                  </Paragraph>
                </div>

                <div className='Auth__inboxContent'>
                  <div className='Auth__envelope'>
                    <Envelope />
                  </div>
                  <Heading text='Check your e-mail' size='xl' element='h1' className='Auth__inboxHeading'/>
                  <Paragraph  size='m' className='Auth__centerText'>
                  We've just checked our records. If they show that we have an account matching <span>{state.email}</span>, we’ve sent a password reset email.
                  </Paragraph>
                  <Button
                    className="Auth__btn inbox"
                    variant="secondary"
                    type="submit"
                    disabled={false}
                    onClick={handleBackToLogin}
                  >
                    Back to login
                  </Button>
                </div>
              </section>
            </section>
            <section className='SignUpDone__PoweredBy'>
            <PoweredBy />
        </section>
    </main>
    )
}
