import {
  Suspense,
  lazy,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ExchangeProvider, { useExchangeContext } from 'contexts/ExchangeContext';
import { Layout, Spinner } from 'components/shared';
import { useAuth } from 'components/Auth/hooks';
import { useUserVerificationState } from 'components/ProfileSetup/hooks/useUserVerificationState';
import {
  VerificationDone,
  VerifiedUserCheck,
} from 'components/ProfileSetup/components';

const ProfileSetup = lazy(() => import('components/ProfileSetup'));
const SetupExchange = lazy(() => import('components/SetupExchange'));
const Dashboard = lazy(() => import('components/Dashboard'));
const Orders = lazy(() => import('components/Orders'));
const Pair = lazy(() => import('components/Pair'));
const Balances = lazy(() => import('components/Balances'));
const CompleteSetup = lazy(
  () => import('components/SetupExchange/components/CompleteSetup')
);

function ProtectedRoutes() {
  const { isAuthenticated } = useAuth();
  const { fetching, isPhoneNumberVerified } = useUserVerificationState();
  const { isSetupComplete } = useExchangeContext();
  const location = useLocation();
  const navigate = useNavigate();
  const navigationState = useMemo(
    () => ({ replace: true, state: { from: location } }),
    [location]
  );

  const checkUserVerificationStatus = useCallback(() => {
    if (isPhoneNumberVerified) {
      if (location.pathname === '/') {
        return navigate('/dashboard', navigationState);
      } else if (
        location.pathname.includes('/setup-exchange') &&
        isSetupComplete
      ) {
        return navigate('/dashboard', navigationState);
      } else {
        return navigate(location.pathname, navigationState);
      }
    } else {
      return navigate('/', navigationState);
    }
    // eslint-disable-next-line
  }, [isPhoneNumberVerified]);

  useEffect(() => {
    if (!fetching && isAuthenticated) {
      checkUserVerificationStatus();
    }
    // eslint-disable-next-line
  }, [fetching]);

  const redirectUnAuthenticatedUser = useCallback(() => {
    if (!isAuthenticated) {
      return navigate('/signin', navigationState);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    redirectUnAuthenticatedUser();
  }, [redirectUnAuthenticatedUser]);

  if (fetching) {
    return <VerifiedUserCheck />;
  }

  return (
    <Fragment>
      <RoutesWithSuspense />
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Spinner />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="/orders"
            element={
              <Suspense fallback={<Spinner />}>
                <Orders />
              </Suspense>
            }
          />
          <Route
            path="/pairs"
            element={
              <Suspense fallback={<Spinner />}>
                <Pair />
              </Suspense>
            }
          />
          <Route
            path="/balances"
            element={
              <Suspense fallback={<Spinner />}>
                <Balances />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </Fragment>
  );
}

function RoutesWithSuspense() {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<ProfileSetup />} />
        <Route path="/setup-exchange" element={<SetupExchange />} />
        <Route path="/phone-verification-done" element={<VerificationDone />} />
        <Route path="/setup-completed" element={<CompleteSetup />} />
      </Routes>
    </Suspense>
  );
}

function ProtectedRoutesWrapper() {
  return (
    <ExchangeProvider>
      <ProtectedRoutes />
    </ExchangeProvider>
  );
}
export default ProtectedRoutesWrapper;
