import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, Heading, Paragraph, TextField } from '@payourse/style-guide';
import { Link, useNavigate } from 'react-router-dom';
import fusionApi from '../../api/fusionApi';
import { forgotPasswordSchema } from './schema';
import Layout from 'components/Auth/components/Layout';
import './styles/Auth.scss';
import { IError } from 'model/signup';
import Alert from './components/Alert';

const initialValues = {
  email: '',
};

export function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate()

  const handleError = (error: IError) => {
    const { statusCode } = error;
    if (statusCode === 404) {
      setErrorMessage(`User with this email ${values.email} does not exist`);
      setIsError(true);
    } else {
      setErrorMessage('Something went wrong');
      setIsError(true);
    }
  };

  const handleResetPasswordRequest = async () => {
    try {
      await fusionApi.forgotPassword({email: values.email})
      navigate('/forgot-password-link-sent', {state: { email: values.email}})
    } catch (error) {
      handleError(error as IError);
    }
  };
  const { errors, getFieldProps, touched, values, handleSubmit } = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: handleResetPasswordRequest,
  });

  const isDisabled = !!errors.email;


  return (
   <Layout>
        <div className="Auth__container">
          <Heading text='Reset password' size='xl' element='h1' className='Auth__title'/>
          <Paragraph size='m' className='Auth__resetNote'>
          We’ll email you a link to reset your password
          </Paragraph>
          <div>
            <form onSubmit={handleSubmit}>
            {isError && <Alert message={errorMessage} isError={isError} />}
            <TextField
                className="Auth__field"
                label="Your email"
                id="email"
                data-testid="email"
                placeholder=""
                error={touched.email ? errors.email : ''}
                {...getFieldProps('email')}
                type="email"
              />
              <Button
                className="Auth__btn login"
                variant="secondary"
                type="submit"
                disabled={isDisabled}
              >
                Send email 
              </Button>

              <Paragraph size='m' className='Auth__footNote-m'>
              <Link className='Auth__term-highlight' to="/signin"> Return to login</Link> 
              </Paragraph>
              <Paragraph size='m' className='Auth__footNote-m'>
              Don’t have a  Simpa account? 
              <Link className='Auth__term-highlight' to="/signup"> Create account</Link> 
              </Paragraph>
            </form>
          </div>
        </div>
     </Layout>
  );
}
